import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IdleService {

  private storageVersion = 100;
  private events = [
    'keypress',
    'change',
    'click',
    'mousemove',
    'scroll',
    'resize'
  ];

  private storage = null;
  private maxIdleTimeSeconds;
  private nowTime;
  private checkInterval = 1000;
  private mainIntervalHandle = null;
  private started = false;
  private activeTime = null;
  private isWarningVisible = false;
  private countdownTimeSeconds;

  private onRunLogout;
  private onShowWarning;
  private onHideWarning;
  private onUpdateCountdown;

  private checkIdleTimeoutHandler;
  private refreshActiveHandler;
  private storageUpdatedHandler;

  public startWatching(options) {
    this.storage = this.createStorage(options.modalPrefix || 'inactivity-modal', 'time');
    this.maxIdleTimeSeconds = options.maxIdleTimeSeconds || 14400;
    this.countdownTimeSeconds = options.countdownTimeSeconds || 60;
    this.nowTime = this.getUnixTime();
    this.checkInterval = options.checkInterval || 1000;
    this.mainIntervalHandle = null;
    this.started = false;
    this.isWarningVisible = false;
    this.activeTime = this.getLastActiveTime();

    this.onRunLogout = options.onRunLogout;
    this.onShowWarning = options.onShowWarning;
    this.onHideWarning = options.onHideWarning;
    this.onUpdateCountdown = options.onUpdateCountdown;

    this.checkIdleTimeoutHandler = this.checkIdle.bind(this);
    this.refreshActiveHandler = this.updateTimeToNow.bind(this);
    this.storageUpdatedHandler = this.handleStorageUpdated.bind(this);

    this.start();
  }

  public continueSession() {
    this.setWarningVisible(false);
    this.updateTimeToNow();
  }

  private getLastActiveTime() {
    return this.storage.get('last-active-time', null) || this.getUnixTime();
  }

  private getIsTimeoutExpired() {
    return this.getUnixTime() -  this.getLastActiveTime() > this.maxIdleTimeSeconds;
  }

  private start() {
    if (this.started) {
      return;
    }

    if (this.getIsTimeoutExpired()) {
      this.stop();
      this.onRunLogout && this.onRunLogout(this);
      return;
    }

    this.updateTimeToNow();
    this.checkIdle();
    this.events.forEach((event) => document.addEventListener(event, this.refreshActiveHandler, false));

    this.mainIntervalHandle = setInterval(this.checkIdleTimeoutHandler, this.checkInterval);
    window.addEventListener('storage', this.storageUpdatedHandler, false);

    this.started = true;
  }

  private touch() {
    this.storage.set('last-active-time', this.getUnixTime());
    window.dispatchEvent(new Event('storage'));
  }

  private updateTimeToNow() {
    if (this.isWarningVisible) {
      return;
    }

    this.touch();
    this.activeTime = this.getLastActiveTime();
  }

  public stop() {
    this.setWarningVisible(false);

    if (!this.started) {
      return;
    }

    clearInterval(this.mainIntervalHandle);
    this.events.forEach((event) => document.removeEventListener(event, this.refreshActiveHandler, false));
    window.removeEventListener('storage', this.storageUpdatedHandler, false);
    this.started = false;
  }

  private setWarningVisible(visible) {
    const previousVisible = this.isWarningVisible;

    this.isWarningVisible = visible;

    if (visible && !previousVisible) {
      this.onShowWarning && this.onShowWarning(this);
    } else if (!visible && previousVisible) {
      this.onHideWarning && this.onHideWarning(this);
    }

    if (visible) {
      this.onUpdateCountdown && this.onUpdateCountdown(this.getCountdownTime());
    }
  }

  private getIdleSeconds() {
    return Math.max(0, this.nowTime - this.activeTime);
  }

  private getCountdownTime() {
    return Math.max(0, this.countdownTimeSeconds - this.getIdleSeconds() + this.maxIdleTimeSeconds);
  }

  public getUnixTime() {
    return Math.round((new Date()).getTime() / 1000);
  }

  public checkIdle() {
    const diff = this.getIdleSeconds();
    this.setWarningVisible(diff >= this.maxIdleTimeSeconds);

    this.nowTime = this.getUnixTime();

    if (this.getCountdownTime() === 0) {
      this.stop();
      this.onRunLogout && this.onRunLogout(this);
    }
  }

  private handleStorageUpdated() {
    if (!this.started) {
      return;
    }

    this.storage.reload();
    this.activeTime = this.getLastActiveTime();
    this.checkIdle();
  }

  private createStorage(storagePrefix, baseName) {
    const appVersion = parseInt(localStorage.getItem(storagePrefix + 'appVersion'), 10);

    if (appVersion !== this.storageVersion) {
      localStorage.setItem(storagePrefix + baseName, '{}');
      localStorage.setItem(storagePrefix + 'appVersion', this.storageVersion + '');
    }

    let data = this.tryParseJSON(localStorage.getItem(storagePrefix + baseName), {});

    return {
      reload: () => {
        data = this.tryParseJSON(localStorage.getItem(storagePrefix + baseName), {});
      },
      set: (name, value) => {
        data[name] = value;
        localStorage.setItem(storagePrefix + baseName, JSON.stringify(data));
      },
      get: (name, defaultValue) => {
        return name in data ? data[name] : (defaultValue || null);
      }
    };
  }

  private tryParseJSON(jsonValue, defaultValue = {}) {
    if (!jsonValue) {
      return defaultValue;
    }

    try {
      return JSON.parse(jsonValue);
    } catch (e) {
      return defaultValue;
    }
  }
}
