import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class YearSessionService {

  private currentYear$: Subject<any> = new Subject<any>();
  selectedYear$: Observable<any> = this.currentYear$.asObservable();

  constructor() { }

  setCurrentYear(year: any) {
    this.currentYear$.next(year);
  }
}
