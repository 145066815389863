import { Component, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { Helpers } from '../../../helpers';

declare var $: any;
declare let KTScrolltop: any;
@Component({
    selector: "app-scroll-top",
    templateUrl: "./scroll-top.component.html",
    encapsulation: ViewEncapsulation.None,
})
export class ScrollTopComponent implements OnInit, AfterViewInit {

    first = true;

    constructor()
    {

    }

    ngOnInit()
    {
    }

    ngAfterViewInit() {
       var _object = new KTScrolltop('kt_scrolltop', {
          offset: 300,
          speed: 600,
        });
    }
}
