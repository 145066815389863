<!-- begin::Page loader (UPDATED)-->
<div class="page-loader page-loader-base">
  <div class="blockui">
    <span>loading...</span>
    <span>
      <div class="spinner spinner-primary"></div>
    </span>
  </div>
</div>
<!-- end::Page loader (UPDATED)-->
<!-- begin:: Page -->
<router-outlet></router-outlet>
<!-- end:: Page -->
<app-scroll-top></app-scroll-top>

<!--Session Time Out modal-->
<div [class.show]="showTimeoutModal" [style.display]="showTimeoutModal ? 'block' : 'none'"
     class="modal fade modalTwo" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog mt-20" role="document">
    <div class="modal-content border p-lg-5">
      <div class="modal-header">
        <h5 class="modal-title">
          Session Timeout
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">
						&times;
					</span>
        </button>
      </div>
      <div class="modal-body">
        <p>
          Your session is about to expire.
        </p>
        <p>Logging out in {{countdown}} seconds...</p>
        <br>
        <div class="progress" style="height: 20px">
          <div class="progress-bar progress-bar-striped progress-bar-animated bg-warning countdown-bar active" role="progressbar"
               [ngStyle]="{width: progress + '%'}">
						<span class="countdown-holder">
							{{ progress }} %
						</span>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="logOut()">
          Logout
        </button>
        <button type="button" class="btn btn-primary" (click)="refreshToken()">
          Stay Connected
        </button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="showTimeoutModal" class="modal-backdrop fade show"></div>
