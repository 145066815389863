import { NoQualificationComponent } from './no-qualification.component';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { LayoutModule } from '../../layouts/layout.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ModalModule } from '../../../modal/modal.module';
import { SharedModule } from '../../../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LayoutModule,
    ModalModule,
    SharedModule
  ],
  declarations: [NoQualificationComponent],
  exports: [NoQualificationComponent],
  providers: [
    DatePipe
  ],
})
export class NoQualificationModule {
}
