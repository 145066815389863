import { CustomService } from './../../../auth/services/custom.service';
import { pdf_download, get_prof_info, send_feed_url } from './../../../auth/_constant/url.component';
import { Component, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { Helpers } from '../../../helpers';
import { CommonService } from './../../../auth/services/common.service';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../../services/user.service';
import { ROUTE_PATHS } from '../../../core/route-paths';
import { YearSessionService } from '../../../auth/services/year-session.service';

declare let KTLayoutHeader: any;
declare var $;
declare var CpdToastr: any;
@Component({
        selector: 'app-header-nav',
        templateUrl: './header-nav.component.html',
        encapsulation: ViewEncapsulation.None,
})
export class HeaderNavComponent implements OnInit, AfterViewInit {
  user: any;
  userAccountNumber: any;
  message = 'cpdportfolio';
  subscription: Subscription;
  year = '';
  sess: any = { end_year: '', id: '', start_year: '', year_plan: '' };
  rollOverList: any = [];
  yearsList: any = [];
  presentSession: any;
  yearId: any;
  loader: boolean;
  professionalInfoList: any;
  sessionValue: any;
  feedbackTypeList: any = ['Help', 'Feedback'];
  userId: any;
  loading: any;
  feedbackform: any;
  isSubmitted = false;

  constructor(
    private router: Router,
    private data: CommonService,
    private _customService: CustomService,
    private userService: UserService,
    private yearSessionService: YearSessionService) {
  }
  ngOnInit()
  {
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    this.userAccountNumber = localStorage.getItem('currentUser_account_number');
    this.getRollOverYearList();
    this.subscription = this.data.currentMessage.subscribe(message => this.message = message);
    this.newMessage('cpdportfolio');
    $('.container').click(function() {
        if ($('.offcanvas-overlay').length > 1) {
          $('.offcanvas-overlay').not(':first').remove();
         }
     });

    this.feedbackform = new FormGroup({
      type: new FormControl('', Validators.required),
      name: new FormControl('', Validators.required),
      email: new FormControl('', Validators.compose([Validators.required, Validators.pattern('[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{1,}[.]{1}[a-zA-Z]{2,}')])),
      notes: new FormControl('', Validators.required),
      subject: new FormControl('', Validators.required)
  });
    this.userId = this.user.id;
    this.setformValue(this.user);
  }

  ngAfterViewInit() {
    KTLayoutHeader.init();
  }

  logOut()
  {
    localStorage.clear();
    this.router.navigate([ROUTE_PATHS.LOGIN]);
    this.subscription.unsubscribe();
  }

  newMessage(message) {
    this.data.changeMessage(message);
    switch (message) {
      case 'cpdportfolio': {
        this.router.navigate([ROUTE_PATHS.DASHBOARD]);
        break;
      }
      case 'settings': {
         // statements;
         this.router.navigate(['/settings_accountinfo']);
         break;
      }
      case 'help': {
         // statements;
         break;
      }
      case 'admin': {
        // statements;
        this.router.navigate(['/admin_dashboard']);
        break;
     }
   }
  }
     // set the form value if the user is logged in //
     setformValue(data?){
      if (data){
          this.feedbackform.controls.name.setValue(data.first_name + ' ' + data.last_name);
          this.feedbackform.controls.email.setValue(data.email);
      }
    }

   getRollOverYearList() {
    Helpers.setLoading(true);
    this.userService.getRolloverYears()
        .subscribe((info: any) => {
            if (info.status != 0) {
                this.rollOverList = info.result;
                localStorage.setItem('latestyear', JSON.stringify(this.rollOverList[this.rollOverList.length - 1]));
                for (let i = this.rollOverList.length; i > 0; i--) {
                    this.yearsList.push(this.rollOverList[i - 1]);
                }
                const date = new Date();
                const year = date.getFullYear();
                let date2: any = '06/01' + '/' + year;
                date2 = new Date(date2);
                let session;
                if (date > date2) {
                    session = this.rollOverList.filter((roll) => roll.start_year == year);
                } else {
                    session = this.rollOverList.filter((roll) => roll.end_year == year);
                }
                const sessValue = JSON.parse(localStorage.getItem('roll'));
                if (sessValue) {
                    this.selectYearSession(1, sessValue, false);
                } else {
                    this.presentSession = session[0].year_plan;
                    this.sess = session[0];
                }
                this.yearId = this.sess.id;
            } else if (info.status == 0 && info.message != 'token_expired') {
                CpdToastr.showMessage('error', info.message);
            }
        },
            err => {
                Helpers.setLoading(false);
                CpdToastr.showMessage('error', 'Internal Server Error!');
            });
  }
  // Method called while selecting year-period //
  selectYearSession(ind, roll, isRegistrationYearChanged= false) {
    this.sess = roll;
    localStorage.setItem('roll', JSON.stringify(this.sess));
    this.presentSession = roll.year_plan;
    this.yearSessionService.setCurrentYear(roll);
    if (isRegistrationYearChanged) {
        setTimeout(() => {
            this.showLnDataReloadedMessage();
    }, 1000);
   }
}
// show success toaster
showLnDataReloadedMessage() {
  //  setTimeout(()=>{
        CpdToastr.showMessage('success', 'Registration Year changed successfully.');
        $('.offcanvas-overlay').click();
  // }, 10000);
}

downloadPDF()
{
  const user = btoa(unescape(encodeURIComponent(JSON.parse(localStorage.getItem('currentUser')).id)));
  const year = btoa(unescape(encodeURIComponent(this.sess.id)));
  let session = this.presentSession;
  session = session.replace('/', '-');
  const url = pdf_download + '/' + user + '/' + year + '/CPDPortfolio_' + session;
  const win = window.open(url, '_blank');
  win.focus();
}
getprofessionalInfo(session) {
  Helpers.setLoading(true);
  this.loader = true;
  this._customService.get(get_prof_info + '/' + this.user.id + '/' + session.id)
      .subscribe((info: any) => {
          setTimeout(() => {
              if (info.status != 0) {

                  this.professionalInfoList = info.result;
                  // console.log(info);

              }else if (info.status == 0 && info.message == 'token_expired'){
                  CpdToastr.showMessage('error', 'Your Session Expired');
                  localStorage.setItem('sessExp', 'true');
              }else {
                  CpdToastr.showMessage('error', info.message);
              }
              Helpers.setLoading(false);
              this.loader = false;
          }, 1500);
      },
          err => {
              Helpers.setLoading(false);
              CpdToastr.showMessage('error', 'Internal Server Error!');
          });
}
headermenuclick(){
  this.sessionValue = JSON.parse(localStorage.getItem('roll'));
  this.getprofessionalInfo(this.sessionValue);
}
    // Method to send the feedback //
    sendFeedback(data){
      this.isSubmitted = true;
      data.id = this.user.id;
      if (this.feedbackform.valid){
          Helpers.setLoading(true);
          this._customService.post(send_feed_url, data)
              .subscribe((info: any) => {
                  Helpers.setLoading(false);
                  if (info.status == 1){
                      this.isSubmitted = false;
                      this.feedbackform.controls.notes.reset();
                      this.feedbackform.controls.type.reset();
                      this.feedbackform.controls.subject.reset();
                      CpdToastr.showMessage('success', info.message);
                  }else if (info.status == 0 && info.message == 'token_expired') {
                      CpdToastr.showMessage('error', 'Your Session Got Expired');
                      localStorage.setItem('sessExp', 'true');
                  } else {
                      CpdToastr.showMessage('error', info.message);
                  }
              },
              err => {
                  Helpers.setLoading(false);
                  CpdToastr.showMessage('error', 'Internal Server Error!');
              });
      }
  }
  cancelContactform(){
    this.feedbackform.controls.notes.reset();
    this.feedbackform.controls.type.reset();
    this.feedbackform.controls.subject.reset();
  }
}
