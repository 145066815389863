import { NgModule } from '@angular/core';
import { HeaderNavComponent } from './header-nav/header-nav.component';
import { MainNavComponent } from './main-nav/main-nav.component';
import { AsideNavComponent } from './aside-nav/aside-nav.component';
import { FooterComponent } from './footer/footer.component';
import { ScrollTopComponent } from './scroll-top/scroll-top.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HrefPreventDefaultDirective } from '../../_directives/href-prevent-default.directive';
import { UnwrapTagDirective } from '../../_directives/unwrap-tag.directive';
import { TermsconditionsComponent } from './termsconditions/termsconditions.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    HeaderNavComponent,
    AsideNavComponent,
    MainNavComponent,
    FooterComponent,
    ScrollTopComponent,
    HrefPreventDefaultDirective,
    UnwrapTagDirective,
    TermsconditionsComponent,
  ],
  exports: [
    HeaderNavComponent,
    MainNavComponent,
    AsideNavComponent,
    FooterComponent,
    ScrollTopComponent,
    HrefPreventDefaultDirective,
    TermsconditionsComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule
  ]
})
export class LayoutModule {
}
