import { baseUrl } from './baseurl.component';

 /* Application based */
export const REFRESH_TOKEN_URL =  baseUrl + 'refresh-token';
export const get_rol_ovr_year_url = baseUrl + 'getrolloveryears';

/* Login && Reset/Forgot Password */
export const LOGOUT_URL =  baseUrl + 'logout-user';
export const chng_pwd =  baseUrl + 'resetpassword';

/* (Registration / Settings)-Account Info */
export const upd_user_basic = baseUrl + 'update-registration-step1';
export const get_bas_info =  baseUrl + 'fetch/basicinfo';


/* (Registration / Settings)-Professional Info */
export const get_nurs_cat_url = baseUrl + 'nursing-category/list';
export const get_type_work_url = baseUrl + 'work-setting/list';
export const get_qual_url = baseUrl + 'qualification/list';
export const add_qual =  baseUrl + 'registration-step2';
export const upd_user_qual = baseUrl + 'update-registration-step2';
export const del_qual = baseUrl + 'delete/qualification';
export const get_prof_info = baseUrl + 'fetch/professionalinfo';
export const get_edit_qual_url = baseUrl + 'check/cpd-linked-qualification';
export const get_age_group_url = baseUrl + 'age-group/list';
export const get_residency_url = baseUrl + 'residency/list';

/*Learning Plan*/
export const lrn_pln =  baseUrl + 'fetch/user-learning-plans';
export const lrn_need = baseUrl + 'learning-need/list';
export const crt_lrn_pln = baseUrl + 'create/learning-plan';
export const usr_qlf_lst = baseUrl + 'fetch/user-qualifications';
export const edt_lrn_lst = baseUrl + 'update/learning-plan';
export const del_lrn_pln = baseUrl + 'delete/learning-plan';
export const lrn_pln_by_year =  baseUrl + 'fetch/user-learning-plans-by-year';

/* Completed CPD */
export const get_act_list_url = baseUrl + 'fetch/cpd-type-options';
export const sv_cmplt_cpd_attch = baseUrl + 'upload/cpdattachments';
export const sv_cmplt_cpd_crt = baseUrl + 'completed-cpd';
export const ftch_cmplt_cpd_grd = baseUrl + 'fetch/completed-cpd-entry';
export const del_cmplt_cpd_grd_row = baseUrl + 'delete/completed-cpd-entry';
export const get_cmplt_cpd_grd_row = baseUrl + 'fetch/completed-cpd-entry-by-id';
export const del_cmplt_cpd_attch = baseUrl + 'delete/cpdattachments';
export const del_cmplt_cmp_attch = baseUrl + 'delete/cmpattachments';

/* Dashboard */
export const get_graph_data_url = baseUrl + 'fetch/cpdrecorded-by-month';
export const get_rem_day_url = baseUrl + 'fetch/remainingdays';
export const get_cpd_data_url = baseUrl + 'fetch/cpdcomplete-vs-required';

/* User-Management */
export const get_users_url =  baseUrl + 'fetch/user-list';
export const upd_user_url = baseUrl + 'update/user-status';
export const user_static = baseUrl + 'user/statics';
export const user_destroy =  baseUrl + 'user/destroy';


/* Contact Us */
export const send_feed_url = baseUrl + 'contact-us';

/* states list */
export const states = baseUrl + 'states';

/* Competencies */
export const get_competency = baseUrl + 'competency';
export const show_competency = baseUrl + 'competency/show';
export const save_competency = baseUrl + 'competency/store';
export const del_competency = baseUrl + 'competency/destroy';
export const upload_competency = baseUrl + 'competency/upload';

// Journal Entry
export const save_journal = baseUrl + 'journal-entry/create';
export const ftch_jrnl_list = baseUrl + 'journal-entry/fetch';
export const get_jrnl_entry_row = baseUrl + 'journal-entry/fetch-by-id';
export const del_jrnl_entry_row = baseUrl + 'journal-entry/delete-by-id';

// PDF download
export const pdf_download = baseUrl + 'pdf';

// enable/disable cron
export const cronvalue = baseUrl + 'cron/list-cron';
export const cronupdate = baseUrl + 'cron/update-cron';
export const runcron = baseUrl + '/cron/send-emails';
