import { Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Router, NavigationStart, NavigationEnd} from '@angular/router';
import {Helpers} from './helpers';
import {AuthenticationService} from './auth/services';
import {REFRESH_TOKEN_URL} from './auth/_constant/url.component';
import {CustomService} from './auth/services/custom.service';
import {IdleService} from './auth/services/idle.service';

declare var CpdToastr: any;
@Component({
  selector: 'body',
  templateUrl: './app.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {

  globalBodyClass = 'header-fixed header-mobile-fixed aside-enabled aside-static';

  showTimeoutModal = false;
  countdown = 60;
  progress = 0;

  constructor(
    private router: Router,
    private idleService: IdleService,
    private authService: AuthenticationService,
    private customService: CustomService
  ) {
  }

  ngOnInit() {
    this.router.events.subscribe((route) => {
      if (route instanceof NavigationStart) {
        Helpers.setLoading(true);
        Helpers.bodyClass(this.globalBodyClass);
      }

      if (route instanceof NavigationEnd) {
        Helpers.setLoading(false);

        if (this.authService.isLoggedIn()) {
          this.idleService.startWatching({
            onRunLogout: () => this.logOut(),
            onShowWarning: () => {
              this.showTimeoutModal = true;
            },
            onHideWarning: () => {
              this.showTimeoutModal = false;
            },
            onUpdateCountdown: (time) => {
              this.countdown = time;
              this.progress = Math.round((60 - time) * 100 / 60);
            }
          });
        } else {
          this.idleService.stop();
        }
      }
    });
  }

  logOut() {
    this.authService.logout();
  }

  refreshToken() {
    this.customService.get(REFRESH_TOKEN_URL)
      .subscribe((result: any) => {
        localStorage.setItem('token', JSON.stringify(result.token));
        localStorage.setItem('expiresIn', JSON.stringify(Math.round((new Date()).getTime() / 1000) + result.expires_in));
      }, err => {
        console.error(err);
        Helpers.setLoading(false);
        CpdToastr.showMessage('error', 'Unable to stay connected!');
      });

    this.idleService.continueSession();
  }
}
