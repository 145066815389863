import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../auth/services';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { CaptchaService } from '../../services/captcha.service';
import { OWNER_CPD, OWNER_NCI } from '../core/user';
import { environment } from '../../../environments/environment';
import { ROUTE_PATHS } from '../../core/route-paths';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit {
  isLoading = false;
  isSubmitted = false;
  template = 'login';
  owner = OWNER_CPD;
  status = '';
  alert: object = {};
  showPassword = false;
  emailForm = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email])
  });

  passwordForm = new FormGroup({
    email: new FormControl({value: '', disabled: true}, [Validators.required]),
    password: new FormControl(null, [Validators.required, Validators.minLength(8)]),
    remember: new FormControl(null)
  });

  isCaptchaSuccess: boolean;

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private reCaptchaV3Service: ReCaptchaV3Service,
    private captchaService: CaptchaService
  ) {
  }

  ngOnInit(): void {
    this.reCaptchaV3Service.execute(environment.captchaSiteKey, 'login', (token) => {
      this.isLoading = true;
      this.captchaService.validate(token).subscribe(
        (response: any) => {
          this.isCaptchaSuccess = !!response.status;
          this.isLoading = false;
        });
    }, {
      useGlobalDomain: false
    });
  }

  get email() {
    return this.emailForm.get('email');
  }

  get password() {
    return this.passwordForm.get('password');
  }

  navigateToCreateAccount() {
    this.router.navigate([ROUTE_PATHS.REGISTER]);
  }

  isNciUser() {
    return this.owner === OWNER_NCI;
  }

	isCPDUser() {
    return this.owner === OWNER_CPD;
  }

  setAlert(status: string, message: string) {
    this.alert = {
      status,
      message
    };
  }

  clearAlert() {
    this.alert = {};
  }

  submitEmail() {
    this.isSubmitted = true;

    if (!this.emailForm.valid) {
      return;
    }

    this.clearAlert();
    this.owner = OWNER_CPD;
    this.status = '';

    this.isLoading = true;

    this.authService.checkEmail(this.email.value).subscribe(
      ({status, message, result}) => {
        if (status === 0) {
          this.setAlert(result.alertStatus, message);
        } else {
          this.passwordForm.get('email').setValue(this.email.value);
          this.template = result?.password ? 'password' : 'reset-password';
        }

        this.owner = result.owner;
        this.status = result.status;
        this.isLoading = false;
        this.isSubmitted = false;
      },
      ({error: {message}}) => {
        this.setAlert('danger', message.email);
        this.isLoading = false;
      }
    );
  }

  submitPassword() {
    this.isSubmitted = true;
    this.clearAlert();

    if (!this.passwordForm.valid) {
      return;
    }

    this.isLoading = true;

    this.authService.login({email: this.email.value, password: this.password.value})
      .subscribe(({status, result, message}) => {
          if (status !== 0) {
            this.authService.saveUserData(result.userdata);
            this.router.navigate([ROUTE_PATHS.DASHBOARD]);
          } else {
            this.setAlert('danger', message);
            this.isLoading = false;
          }
        },
        () => {
          this.isLoading = false;
        });
  }

  submitResetPasswordRequest() {
    this.isLoading = true;
    this.authService.requestPasswordReset(this.email.value)
      .subscribe(() => {
        this.template = 'reset-password-success';
        this.isLoading = false;
      }, () => {
        this.isLoading = false;
      });
  }

  goBack() {
    this.owner = OWNER_CPD;
    this.template = 'login';
    this.isSubmitted = false;
    this.emailForm.reset();
    this.passwordForm.reset();
    this.clearAlert();
  }

  resetPassword() {
    this.template = 'reset-password';
  }
}
