import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { Helpers } from '../helpers';
import { ScriptLoaderService } from '../_services/script-loader.service';
import { Subscription } from 'rxjs';
import { CommonService } from '../auth/services/common.service';

declare let KTApp: any;
declare let KTUtil: any;
declare let mLayout: any;

@Component({
  selector: ".kt-grid.kt-grid--hor.kt-grid--root.kt-page",
  templateUrl: "./theme.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class ThemeComponent implements OnInit {
  message = 'all';
  subscription: Subscription;

  constructor(private script: ScriptLoaderService, private _router: Router, private data: CommonService) {

  }

  ngOnInit() {
    this.script.loadScripts('body', [
      'assets/vendors/base/vendors.bundle.js',
      'assets/demo/default/custom/components/base/toastr.js'
    ], true)
      .then(result => {
        Helpers.setLoading(false);
      });
    this._router.events.subscribe((route) => {
      if (route instanceof NavigationStart) {
        (<any>KTUtil).scrollTop();
        Helpers.setLoading(true);
      }
      if (route instanceof NavigationEnd) {
        // init required js
        (<any>KTApp).init();
        (<any>KTUtil).init();
        Helpers.setLoading(false);

        if ($('#kt_aside').hasClass('aside-on')) {
          $('#kt_aside_mobile_toggle').trigger('click');
        }
      }
    });

    // this.subscription = this.data.currentMessage.subscribe(message => this.message = message);
    $("#kt_header_mobile_topbar_toggle").click(function() {
      $("#kt_quick_user").addClass("offcanvas-on");
    })
    $("#kt_quick_user_close").click(function() {
      $("#kt_quick_user").removeClass("offcanvas-on");
    })
  }
  newMessage(message) {
    this.data.changeMessage(message);
  }

}
