<!--NCPD Logo-->
<div *ngIf="isNciUser()" class="p-4 bg-ncpd ncpd-login-logo animate__animated animate__zoomIn">
  <img src="/assets/app/media/img/logo-ncpd-square-1590px.png" class="max-w-115px"
       title="This account is provided to you by Nursing CPD"/>
</div>
<div class="card card-custom max-w-400px w-lg-400px min-w-300px">
	<!--Spinner-->
  <ng-container *ngIf="isPageLoading; else elseTemplate">
		<div class="d-flex flex-column align-items-center p-15">
    	<div class="spinner spinner-primary spinner-lg"></div>
		</div>
  </ng-container>
  <ng-template #elseTemplate>
    <div id="access-step-1.3" class="card-body p-6 px-sm-15 width-100">
      <div class="my-7">
        <h2 class="font-weight-boldest">New password</h2>
        <p class="mt-4 mb-0">Set a password for your CPD Portfolio account.</p>
				<p *ngIf="isNciUser()" class="text-ncpd animate__animated animate__zoomIn">Your account is provided by
          NursingCPD.</p>
      </div>
			<!--Alert - Expired link-->
      <div *ngIf="error" class="alert alert-custom alert-light-danger text-left" role="alert">
        <div class="alert-icon align-items-start pt-1">
          <i class="fas fa-exclamation-circle font-size-h4"></i>
        </div>
        <div class="alert-text">{{ error }} <br> 
					<a href="javascript:;" (click)="navigateLogin()" class="btn btn-danger mt-3"><u>Send new link</u></a>
				</div>
      </div>
			<!--Form-->
      <form [formGroup]="formGroup" (ngSubmit)="handlePasswordSetup()">
        <div class="form-group mt-10 mb-0">
					<!--Email-->
					<div class="form-group mb-5">
						<p class="text-muted">{{ email }}</p>
					</div>
					<!--Password-->
          <div class="input-group">
            <input
              [class.is-invalid]="password.invalid && (password.touched || isSubmitted)"
              [class.is-valid]="password.valid && password.touched"
              class="form-control py-4"
              [type]="showPassword ? 'text' : 'password'"
              minlength="8"
              maxlength="20"
              placeholder="New Password"
              formControlName="password"
              name="password"
            />
            <div class="input-group-append">
              <span class="input-group-text">
                <i class="font-size-md fas" title="Show/hide password"
                   [ngClass]="showPassword ? 'fa-eye-slash' : 'fa-eye'"
                   (click)="showPassword=!showPassword"></i>
              </span>
            </div>
          </div>
					<!--Validations-->
          <div class="d-flex flex-column text-left text-muted pl-3 mt-3">
            <div>
              <i class="far fa-check-circle mr-1"
                 [ngClass]="password.hasError('atLeastOneCapitalLetter') ? 'text-muted' : 'text-success'"></i>
              At least one capital letter
            </div>
						<div>
              <i class="far fa-check-circle mr-1"
                 [ngClass]="password.hasError('atLeastOneNumber') ? 'text-muted' : 'text-success'"></i>
              At least one number
            </div>
						<div>
              <i class="far fa-check-circle mr-1"
                 [ngClass]="password.errors?.required || password.errors?.minlength ? 'text-muted' : 'text-success'"></i>
              8 or more characters
            </div>
          </div>
        </div>
        <div class="mt-7 mb-3">
          <button class="btn btn-primary w-175px py-3"
                  [ngClass]="{'spinner spinner-right spinner-light': isLoading}">Save
          </button>
        </div>
				<a class="mt-lg-5" href="https://accounts.cpdportfolio.com.au/login">Cancel</a>
      </form>
    </div>
  </ng-template>
</div>