import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountSetupComponent } from './account-setup.component';
import { LayoutModule } from '../../layouts/layout.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from '../../../shared/shared.module';
import { ModalModule } from '../../../modal/modal.module';

@NgModule({
    imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      LayoutModule,
      SharedModule,
      ModalModule
    ],
    declarations: [AccountSetupComponent],
    exports: [AccountSetupComponent],
    providers: []
})
export class AccountSetupModule { }
