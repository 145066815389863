import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CustomService {

  constructor(private http: HttpClient) {}

  get(url) {
    return this.http.get(url);
  }

  post(url, data) {
    return this.http.post(url, data);
  }

  post_wth_token(url, data) {
    return this.http.post(url, data);
  }

  update(url, data) {
    return this.http.put(url, data);
  }

  delete(url, id) {
    url = url + '/' + id;
    return this.http.delete(url);
  }

  search(url) {
    return this.http.get(url);
  }

}
