<!-- begin:: Aside -->
<button class="kt-aside-close " id="kt_aside_close_btn"><i class="la la-close"></i></button>
<div class="aside aside-left d-flex flex-column flex-row-auto" id="kt_aside">
	<!-- begin:: Aside Menu -->
	<div class="aside-menu-wrapper flex-column-fluid" id="kt_aside_menu_wrapper">
		<div id="kt_aside_menu" class="aside-menu" data-menu-vertical="1" data-menu-scroll="1" data-menu-dropdown-timeout="500">
			<!--begin: MENU (CPD Portfolio)-->
			<ul *ngIf="message === 'cpdportfolio' || message === 'all'" class="menu-nav shadow-xs px5">
				<!--Item-Dashboard-->
				<li class="menu-item my-2" routerLinkActive="menu-item-active" routerLinkActiveOptions="{ exact: true }" aria-haspopup="true" >
					<a routerLink="/dashboard" class="menu-link">
						<i class="menu-icon flaticon2-poll-symbol"></i>
						<span class="menu-text">Dashboard</span>
					</a>
				</li>
				<!--Item-Learning Plan-->
				<li class="menu-item my-2" routerLinkActive="menu-item-active" routerLinkActiveOptions="{ exact: true }" aria-haspopup="true" >
					<a routerLink="/learning_plan" class="menu-link">
						<i class="menu-icon flaticon2-analytics"></i>
						<span class="menu-text">Learning Plan</span>
						<span class="label label-light font-weight-bold label-inline">Step 1</span>
					</a>
				</li>
				<!--Item-Recorded CPD-->
				<li class="menu-item my-2" routerLinkActive="menu-item-active" routerLinkActiveOptions="{ exact: true }" aria-haspopup="true">
					<a routerLink="/completed_cpd" class="menu-link">
						<i class="menu-icon flaticon2-folder"></i>		
						<span class="menu-text">CPD Records</span>
						<span class="label label-light font-weight-bold label-inline">Step 2</span>
					</a>
				</li>
				<!--SECTION-->
				<li class="menu-section" >
            <h4 class="menu-text">Extras</h4>
        </li>
				<!--Item-Competencies-->
				<li class="menu-item my-2" routerLinkActive="menu-item-active" routerLinkActiveOptions="{ exact: true }" aria-haspopup="true">
					<a routerLink="/competencies" class="menu-link">
						<i class="menu-icon flaticon2-cup"></i>
						<span class="menu-text">Competencies</span>                        					
					</a>
				</li>
				<!--Item-Journal Entries-->
				<li class="menu-item my-2" routerLinkActive="menu-item-active" routerLinkActiveOptions="{ exact: true }" aria-haspopup="true">
					<a routerLink="/journal_entries" class="menu-link">
						<i class="menu-icon flaticon2-pen"></i>					
						<span class="menu-text">Shift Diary</span>                        					
					</a>
				</li>
			</ul>
			<!--end: MENU (CPD Portfolio)-->
			<!--begin: MENU (settings)-->
			<ul *ngIf="message === 'settings' || message === 'all'" class="menu-nav p-5 pb-0">
				<!--SECTION-->
				<li class="menu-section mt-0">
            <h4 class="menu-text">Settings Menu</h4>
        </li>
				<!--Item-Account Info-->
				<li class="menu-item my-2" routerLinkActive="menu-item-active" routerLinkActiveOptions="{ exact: true }" aria-haspopup="true">
					<a routerLink="/settings_accountinfo" class="menu-link">						
						<i class="menu-icon fas fa-user-cog"></i>				
						<span class="menu-text">Account Info</span>
					</a>
				</li>
				<!--Item-Qualifications-->
				<li class="menu-item my-2" routerLinkActive="menu-item-active" routerLinkActiveOptions="{ exact: true }" aria-haspopup="true">
					<a routerLink="/settings_qualification" class="menu-link">
						<i class="menu-icon fas fa-user-graduate"></i>						
						<span class="menu-text">Qualifications</span>
					</a>
				</li>
				<!--Item-Membership-->
				<li class="menu-item my-2" routerLinkActive="menu-item-active" routerLinkActiveOptions="{ exact: true }" aria-haspopup="true">
					<a routerLink="/settings_membership" class="menu-link">
						<i class="menu-icon fas fa-user-lock"></i>							
						<span class="menu-text">Membership</span>
					</a>
				</li>
        <!--Item-Password-->
				<li class="menu-item my-2" routerLinkActive="menu-item-active" routerLinkActiveOptions="{ exact: true }" aria-haspopup="true">
					<a routerLink="/change_password" class="menu-link">						
						<i class="menu-icon fas fa-lock"></i>							
						<span class="menu-text">Password</span>
					</a>
				</li>
			</ul>
			<!--end: MENU (settings)-->
			
			<!--begin:: Admin Menu Nav-->
			<ul *ngIf="user.role_id == '2' && (message === 'admin' || message === 'all')" class="menu-nav p-5 pb-0">
				<li class="menu-section mt-0">
					 <h4 class="menu-text">Admin Menu</h4>
				</li>
				<!--Dashboard-->
				<li class="menu-item my-2" routerLinkActive="menu-item-active" routerLinkActiveOptions="{ exact: true }" aria-haspopup="true">
					<a routerLink="/admin_dashboard" class="menu-link">						
						<i class="menu-icon fas fa-chart-bar"></i>			
						<span class="menu-text">Dashboard</span>
					</a>
				</li>
				<!--Item-User List-->
				<li class="menu-item my-2" routerLinkActive="menu-item-active" routerLinkActiveOptions="{ exact: true }" aria-haspopup="true">
					<a routerLink="/user_list" class="menu-link">						
						<i class="menu-icon fas fa-users-cog"></i>	
						<span class="menu-text">User List</span>
					</a>					
				</li>
				<!--Item-User List-->
				<li class="menu-item my-2" routerLinkActive="menu-item-active" routerLinkActiveOptions="{ exact: true }" aria-haspopup="true">
					<a routerLink="/user_management" class="menu-link">						
						<i class="menu-icon fas fa-users-cog"></i>	
						<span class="menu-text">User Management</span>
					</a>					
				</li>
			</ul>
			
		</div>
	</div>
	<!-- end:: Aside Menu -->
	<!-- Conditional LOGO for NCI members -->
	<span *ngIf="(message === 'cpdportfolio' || message === 'all') && user.owner==2" class="p-5 text-center bg-ncpd mt-5 rounded">
		<div class="text-white font-size-sm mb-2">Account provided by:</div>
		<img src="/assets/app/media/img/logo-ncpd-square-1590px.png" class="max-w-140px" title="This account is provided to you by Nursing CPD"/> 
	</span>
	<!-- End content -->
</div>
<!-- end:: Aside -->
