<app-header-nav></app-header-nav>
<!--MOBILE HEADER-->
<div id="kt_header_mobile" class="header-mobile bg-primary header-mobile-fixed">
  <a href="index.html"> <img alt="Logo" src="./assets/app/media/img/logos/logo-cpd-white.png" class="max-h-50px" /> </a>
  <div class="d-flex align-items-center">
    <!--BUTTON (submit help inquiry)-->
    <button class="btn btn-icon bg-white-o-30 mr-2" id="" data-toggle="modal" data-target="#contactModal">
      <i class="far fa-question-circle icon-xl"></i>
    </button>
    <!--BUTTON (toggle right off-canvas user menu)-->
    <button class="btn btn-icon bg-white-o-30 mr-5" id="kt_header_mobile_topbar_toggle">
      <i class="la la-user icon-xl"></i>
    </button>
    <!--BUTTON (toggle left off-canvas navigation menu)-->
    <button (click)="newMessage('all')" class="btn p-0 burger-icon burger-icon-left" id="kt_aside_mobile_toggle">
      <span></span>
    </button>
  </div>
</div>
<!-- begin::Body -->
<!--APP WRAPPERS-->
<div class="d-flex flex-column flex-root">
  <div class="d-flex flex-row flex-column-fluid page">
    <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
      <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
        <div class="container">
          <div class="d-lg-flex flex-row-fluid">
            <app-aside-nav></app-aside-nav>
            <div class="content-wrapper flex-row-fluid">
              <router-outlet></router-outlet>
            </div>
            <app-scroll-top></app-scroll-top>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- end:: Body -->
<app-footer></app-footer>