import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { map, shareReplay } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { RolloverYear } from '../model/rollover-year';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  url: string = environment.apiUrl;

  cachedRolloverYears$: Observable<RolloverYear[]>;

  constructor(private http: HttpClient) { }

  getContextOfPracticeInfo() {
    return this.http.get(this.url + 'user/context-of-practice-info');
  }

  getRolloverYears(): Observable<RolloverYear[]> {
    if (!this.cachedRolloverYears$) {
      this.cachedRolloverYears$ = this.http.get(this.url + 'getrolloveryears').pipe(
        map((response: any) => response),
        shareReplay(1)
      );
    }

    return this.cachedRolloverYears$;
  }

  register(body) {
    return this.http.post(this.url + 'user/register', body);
  }
}
