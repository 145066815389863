<div class="d-flex flex-center flex-row-fluid bgi-login">
    <div class="d-flex flex-center flex-row-fluid min-vh-100">
      <div class="login-form text-center p-3 position-relative overflow-hidden mb-40">
        <!--LOGO-->
        <div class="d-flex flex-center my-15">
          <a href="https://www.cpdportfolio.com.au">
            <img src="./assets/app/media/img/logos/cpdportfolio_logo_teal--230x50.png" class="max-h-150px"
                 alt="CPD Portfolio Logo"/>
          </a>
        </div>
        <router-outlet></router-outlet>
      </div>
    </div>
</div>