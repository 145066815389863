import {AbstractControl, ValidationErrors} from '@angular/forms';

export class PasswordValidators {
  static atLeastOneNumber(control: AbstractControl): ValidationErrors | null {
    if (/[0-9]+/.test(control.value)) {
      return null;
    }
    return {atLeastOneNumber: true};
  }
  static atLeastOneCapitalLetter(control: AbstractControl): ValidationErrors | null {
    if (/[A-Z]+/.test(control.value)) {
      return null;
    }
    return {atLeastOneCapitalLetter: true};
  }
}
