import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

interface Common {
  [header: string]: string | string[];
}

interface Options {
  headers?: HttpHeaders | Common;
  observe?: 'response' | 'body' | 'events';
  params?: HttpParams | Common;
  reportProgress?: boolean;
  responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
  withCredentials?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class RequestService {
  protected baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.apiUrl;
  }

  get(url: string, options?: Options, fullResponse: boolean = false): Observable<any> {
    return this.http.get(this.baseUrl + url, <any>{
      observe: fullResponse ? 'response' : 'body',
      ...options,
    });
  }

  post(url: string, body: any, options?: Options): Observable<any> {
    return this.http.post(this.baseUrl + url, body, <any>{ ...options });
  }

  put(url: string, body: any, options?: Options): Observable<any> {
    return this.http.put(this.baseUrl + url, body, <any>{ ...options });
  }

  patch(url: string, body: any, options?: Options): Observable<any> {
    return this.http.patch(this.baseUrl + url, body, <any>{ ...options });
  }

  delete(url: string): Observable<any> {
    return this.http.delete(this.baseUrl + url, { });
  }
}
