import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TabComponent, TabsComponent } from './tab';
import { ConfirmationComponent, ConfirmationService } from './confirmation';

@NgModule({
  declarations: [
    TabComponent,
    TabsComponent,
    ConfirmationComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    TabComponent,
    TabsComponent,
    ConfirmationComponent
  ],
  providers: [ConfirmationService]
})
export class SharedModule {
}
