<!--begin::Header-->
<div id="kt_header" class="header flex-column header-fixed">
	<div class="header-top">
		<div class="container"> 
			<!--Topbar Left (header tabs)-->
			<div id="topbar_left" class="d-none d-lg-flex align-items-center mr-3"> 
				<!--Logo--> 
				<a routerLink="/dashboard" class="mr-10"> <img alt="Logo" src="./assets/app/media/img/logos/logo-cpdportfolio-white--205x45px.png" class="mr-lg-25" /> </a> 
				<!--Tab Navs(for desktop mode)-->
				<ul class="header-tabs nav align-self-end" role="tablist">
					<li class="nav-item"> 
						<a [ngClass]="message=='cpdportfolio'? 'active':''" href="javascript:;" (click)="newMessage('cpdportfolio')" class="nav-link py-4 px-6" data-toggle="" data-target="" role="tab" aria-selected="true">Portfolio</a> 
					</li>
					<li class="nav-item mr-3"> 
						<a [ngClass]="message=='settings'? 'active':''" href="javascript:;" (click)="newMessage('settings')" class="nav-link py-4 px-6" data-toggle="" data-target="" role="tab" aria-selected="false">Settings</a> 
					</li>
					
					<!--note::Menu for Admin User Only-->
					<li *ngIf="user.role_id == '2'" class="nav-item mr-3"> 
						<a [ngClass]="message=='admin'? 'active':''" (click)="newMessage('admin')" href="javascript:;" class="nav-link py-4 px-6" data-toggle="" data-target="" role="tab" aria-selected="false">Admin</a> 
					</li>
					
				</ul>
			</div>
			<!--Topbar Right-->
			<div id="topbar_right" class="topbar">
				<!--BUTTON (help)-->
				<div class="topbar-item mr-3" data-container="body" data-placement="top" data-theme="dark" data-toggle="tooltip" title="" data-original-title="Send Enquiry">
					<div class="btn text-white bg-white-o-30" data-toggle="modal" data-target="#contactModal"> <i class="far fa-question-circle text-white"></i> <span class="show-xl">Help</span> </div>
				</div>
				<!--BUTTON (print)-->
				<div class="topbar-item mr-3">
					<div class="btn text-white bg-white-o-30" data-container="body" data-placement="top" data-theme="dark" data-toggle="tooltip" title="" data-original-title="Print to PDF" (click)="downloadPDF()" href="javascript:;"> <i class="flaticon2-printer text-white mr-1"></i><span class="show-xl">Print</span></div>
				</div>
				<!--BUTTON (registration year selector)-->
				<div class="topbar-item mr-3">
					<div class="btn-group">
						<button type="button" class="btn bg-white-o-30 text-white dropdown-toggle" id="dropdownMenuReference" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-reference="parent"><span data-container="body" data-placement="top" data-theme="dark" data-toggle="tooltip" title="" data-original-title="Change Registration Year"><i class="text-white far fa-calendar-alt mr-1"></i></span> <span class="show-xl" data-container="body" data-placement="top" data-theme="dark" data-toggle="tooltip" title="" data-original-title="Change Registration Year">{{presentSession}}</span></button>
						<div class="dropdown-menu dropdown-menu-sm dropdown-menu-right" aria-labelledby="dropdownMenuReference" style="">
							<ng-container *ngFor="let roll of yearsList; let in=index"> 
								<a *ngIf="roll.year_plan == presentSession" href="" class="dropdown-item active" (click)="selectYearSession(in,roll, true)">
									<i class="text-white far fa-calendar-alt mr-4"></i>{{roll.year_plan}}
								</a> 
								<a *ngIf="roll.year_plan != presentSession" href="" class="dropdown-item" (click)="selectYearSession(in,roll, true)">
									<i class="text-dark-50 far fa-calendar-alt mr-4"></i> {{roll.year_plan}}
								</a> 
							</ng-container>
						</div>
					</div>
				</div>
				<!--BUTTON (user menu toggle)-->
				<div class="topbar-item" (click)="headermenuclick()" >
					<div class="btn btn-icon bg-white-o-30" id="kt_quick_user_toggle" data-container="body" data-placement="top" data-theme="dark" data-toggle="tooltip" title="" data-original-title="Toggle User Menu"> <i class="icon-xl la la-user text-white"></i> </div>
				</div>
			</div>
		</div>
	</div>
</div>
<!--Off Canvas Right (User Menu)-->
<div id="kt_quick_user" class="offcanvas offcanvas-right p-10"> 
	<!--Header-->
	<div class="offcanvas-header d-flex align-items-center justify-content-between pb-5" kt-hidden-height="40" style="">
		<h3 class="font-weight-boldest m-0">User Menu</h3>
		<a href="#" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_user_close"><i class="ki ki-close icon-xs text-muted"></i></a> </div>
	<!--Content-->
	<div class="offcanvas-content pr-5 mr-n5 scroll ps ps--active-y" style="">
		<div class="text-dark-50 font-size-h5">{{user.first_name}} {{user.last_name}}</div>
		
		<ul class="list-inline text-dark-25">
			<li class="list-inline-item">ID: </li>
			<li class="list-inline-item">{{user.id}}</li>
		</ul>
		<h6 class="font-weight-boldest mt-5">Qualification(s)</h6>
		<ul class="list-unstyled">
			<li *ngFor="let item of professionalInfoList;let i = index">{{item.qualification_name}}</li>
		</ul>
		
		
		<!--begin::Registration Year Selector-->
		<div class="d-flex">
			<div class="btn-group mt-5">
				<button type="button" class="btn btn-primary dropdown-toggle" id="dropdownMenuReference" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-reference="parent"><i class="text-white far fa-calendar-alt mr-1"></i> {{presentSession}}</button>
				<div class="dropdown-menu dropdown-menu-sm dropdown-menu-left" aria-labelledby="dropdownMenuReference" style="">
					<ng-container *ngFor="let roll of yearsList; let in=index"> <a *ngIf="roll.year_plan == presentSession" href="" class="dropdown-item active" (click)="selectYearSession(in,roll, true)">{{roll.year_plan}}</a> <a *ngIf="roll.year_plan != presentSession" href="" class="dropdown-item" (click)="selectYearSession(in,roll, true)"> {{roll.year_plan}}</a> </ng-container>
				</div>
			</div>
		</div>
		<div class="d-flex">
			<div class="btn btn-danger mt-5" id="" (click)="logOut()"> <span class="">Logout</span> </div>
		</div>
		<!--end::Registration Year Selector-->
		<div class="separator separator-dashed mt-8 mb-5"></div>
		
		<!--begin::Navi-->
		<div class="navi navi-spacer-x-0 p-0"> 
			<!--Print to PDF--> 
			<a title="Print the current years portfolio to PDF" class="navi-item" (click)="downloadPDF()" href="javascript:;">
			<div class="navi-link">
				<div class="symbol symbol-50 bg-light mr-3">
					<div class="symbol-label"> <i class="icon-2x text-primary flaticon2-printer"></i> </div>
				</div>
				<div class="navi-text">
					<div class="font-weight-bold">Print to PDF</div>
				</div>
			</div>
			</a> </div>
		<div class="separator separator-dashed my-7"></div>
		<!--end::Navi--> 
		<!--begin::Notifications-->
		<h5 class="mb-5">Notifications</h5>
		<div class="alert alert-light" role="alert">No new notifications.</div>
		<!--end::Notifications--> 
	</div>
</div>
<!-- Modal NEED HELP-->
<div class="modal fade" id="contactModal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
	<form [formGroup]="feedbackform" class="kt-form">
		<div class="modal-dialog modal-md" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<div class="modal-title" id="contactModalLabel">Send Message</div>
					<button type="button" class="btn btn-close" data-dismiss="modal" aria-label="Close" (click)="cancelContactform()"> <i aria-hidden="true" class="ki ki-close"></i> </button>
				</div>
				<div class="modal-body">
					<div class="form-group mb-8">
						<div class="alert alert-custom alert-light-info" role="alert">
							<div class="alert-icon">
							<i class="icon-2x flaticon-chat"></i>
							</div>
							<div class="alert-text"></div>
							<p class="alert-text mb-0">Check to see if anyone is online to chat (bottom right hand corner of the page). Alternatively, submit your inquiry below or email us at <span class="font-weight-bolder">mail@cpdportfolio.com.au</span></p>
						</div>
					</div>
					<!--Call in the user specific ID and email to display for the user here. This should be submitted with the form.-->
					<!--<div class="mb-5 text-muted font-size-sm text-center"> 
						<i class="icon-lg la la-user"></i> 
						<span class="ml-2"> {{user.email}}</span> 
					</div>-->
					<div class="form-group row">
						<div class="col-lg-8">
							<label>Message Type<span class="text-danger">*</span></label>
							<select formControlName="type" aria-describedby="country-error" class="form-control" [class.is-invalid]="feedbackform['controls'].notes.errors && isSubmitted" [class.is-valid]="feedbackform['controls'].type.valid">
								<option disabled value="">Select type</option>
								<option *ngFor="let item of feedbackTypeList" value="{{item}}">{{item}}</option>
							</select>
							<div *ngIf="feedbackform['controls'].type.errors?.required && isSubmitted" class="text-danger"> *required </div>
						</div>
					</div>
					<div class="form-group row">
						<div class="col-lg-12">
							<label>Subject<span class="text-danger">*</span></label>
							<input formControlName="subject" type="text" class="form-control" placeholder="Message Subject" [class.is-invalid]="feedbackform['controls'].notes.errors && isSubmitted" [class.is-valid]="feedbackform['controls'].subject.valid" maxlength="150">
							<span *ngIf="feedbackform['controls'].subject.errors?.required && isSubmitted" class="text-danger"> *required </span> </div>
					</div>
					<div class="form-group row">
						<div class="col-lg-12">
							<label class="">Message<span class="text-danger">*</span></label>
							<textarea [class.is-invalid]="feedbackform['controls'].notes.errors && isSubmitted" [class.is-valid]="feedbackform['controls'].notes.valid" rows="4" minlength="1" maxlength="1000" formControlName="notes" class="form-control" placeholder="Enter your message"></textarea>
							<span *ngIf="feedbackform['controls'].notes.errors?.required && isSubmitted" class="text-danger"> *required </span> </div>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-light font-weight-bold" data-dismiss="modal" (click)="cancelContactform()">Cancel</button>
					<button type="submit" class="btn btn-primary" (click)="sendFeedback(feedbackform.value)">Submit</button>
				</div>
			</div>
		</div>
	</form>
</div>