import { Component, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { CommonService } from './../../../auth/services/common.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ROUTE_PATHS } from "../../../core/route-paths";

declare let KTLayoutAside: any;
@Component({
    selector: "app-aside-nav",
    templateUrl: "./aside-nav.component.html",
    encapsulation: ViewEncapsulation.None,
})
export class AsideNavComponent implements OnInit, AfterViewInit {

    user:any={};
    message:string='cpdportfolio';
    subscription: Subscription;

    constructor(private router:Router, private data: CommonService) {
    }

    ngOnInit() {
        this.user = JSON.parse(localStorage.getItem('currentUser'));
        this.subscription = this.data.currentMessage.subscribe(message => this.message = message)
    }

    ngAfterViewInit() {
       KTLayoutAside.init();
    }

    // Method called to log out button //
    logOut(){
        localStorage.clear();
        this.router.navigate([ROUTE_PATHS.LOGIN])
        this.subscription.unsubscribe();
    }
}
