import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class ConfirmationService {
  private subject = new Subject<any>();

  constructor() {}

  confirmThis(message: string, title: string, yesFn: () => void, noFn: () => void) {
    this.setConfirmation(message, title, yesFn, noFn);
  }

  setConfirmation(text: string, title: string, yesFn: () => void, noFn: () => void) {
    this.subject.next({
      text,
      title,
      yesFn: () => {
        this.subject.next();
        yesFn();
      },
      noFn: () => {
        this.subject.next();
        noFn();
      }
    });

  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }
}
