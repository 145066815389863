import { NgModule } from '@angular/core';
import { ThemeComponent } from './theme.component';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../auth/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: ThemeComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'settings_membership',
        loadChildren: () => import('./pages/settings-membership/settings-membership.module').then(m => m.SettingsMembershipModule)
      },
      {
        path: 'settings_accountinfo',
        loadChildren: () => import('./pages/settings-accountinfo/settings-accountinfo.module').then(m => m.SettingsAccountinfoModule)
      },
      {
        path: 'settings_qualification',
        loadChildren: () => import('./pages/settings-qualification/settings-qualification.module').then(m => m.SettingsQualificationModule)
      },
      {
        path: 'learning_plan',
        loadChildren: () => import('./pages/learning_plan/learning_plan.module').then(m => m.LearningPlanModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'completed_cpd',
        loadChildren: () => import('./pages/completed_cpd/completed_cpd.module').then(m => m.CompletedCpdModule)
      },
      {
        path: 'journal_entries',
        loadChildren: () => import('./pages/journal-entries/journal-entries.module').then(m => m.JournalEntriesModule)
      },
      {
        path: 'competencies',
        loadChildren: () => import('./pages/competencies/competencies.module').then(m => m.MyCompetencyModule)
      },
      {
        path: 'user_management',
        loadChildren: () => import('./pages/user-management/user-management.module').then(m => m.UserManagementModule)
      },
      {
        path: 'admin_dashboard',
        loadChildren: () => import('./pages/admin-dashboard/admin-dashboard.module').then(m => m.AdminDashboardModule)
      },
      {
        path: 'user_list',
        loadChildren: () => import('./pages/user-list/user-list.module').then(m => m.UserListModule)
      },
      {
        path: 'change_password',
        loadChildren: () => import('./pages/change_password/change_password.module').then(m => m.ChangePasswordModule)
      },
      {
        path: 'not_found',
        loadChildren: () => import('./pages/default/not-found/not-found.module').then(m => m.NotFoundModule)
      },
      {
        path: 'contact_us',
        loadChildren: () => import('./pages/contact-us/logged-in-contact-us/contact-us.module').then(m => m.ContactUsModule)
      },
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },

    ]
  },
  {
    path: 'contact',
    loadChildren: () => import('./pages/contact-us/contact_us.module').then(m => m.FeedbackModule)
  },
  {
    path: 'stripepayment',
    loadChildren: () => import('./pages/stripe-payment/stripe-payment.module').then(m => m.StripePaymentModule)
  },
  {
    path: 'join',
    loadChildren: () => import('./pages/create-account/create-account.module').then(m => m.CreateAccountModule)
  },
  {path: '**', redirectTo: 'not_found'}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ThemeRoutingModule {
}
