import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CaptchaService {
  url: string = environment.apiUrl;

  constructor(private http: HttpClient) { }

  validate(token: string) {
    return this.http.post(this.url + 'captcha', {
      token
    });
  }
}
