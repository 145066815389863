import { LOGOUT_URL } from '../_constant/url.component';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { finalize } from 'rxjs/operators';
import { RequestService } from '../../core/request.service';
import { Observable } from 'rxjs';
import { ROUTE_PATHS } from '../../core/route-paths';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {

  constructor(
    public transport: RequestService,
    private router: Router) {
  }

  login(user) {
    return this.transport.post('login', user);
  }

  saveUserData(userData) {
    const user = {
      id: userData.id,
      first_name: userData.first_name,
      last_name: userData.last_name,
      email: userData.email,
      contact_number: userData.contact_number,
      address: userData.address,
      suburb: userData.suburb,
      city: userData.city,
      postcode: userData.postcode,
      state: userData.state,
      active: userData.active,
      last_login: userData.last_login,
      role_id: userData.role_id,
      owner: userData.owner
    };
    localStorage.setItem('currentUser_account_number', userData.account_number);
    localStorage.setItem('currentUser', JSON.stringify(user));
    localStorage.setItem('token', JSON.stringify(userData.token));
    localStorage.setItem('expiresIn', JSON.stringify(Math.round((new Date()).getTime() / 1000) + userData.expires_in));
  }

  logout() {
    const token = JSON.parse(localStorage.getItem('token'));
    const headers = (new HttpHeaders()).append('Authorization', `bearer ${token}`);

    localStorage.clear();

    this.transport.get(LOGOUT_URL, {headers}).pipe(
      finalize(() => {
        this.router.navigate([ROUTE_PATHS.LOGIN]);
      })
    ).subscribe();
  }

  isLoggedIn() {
    return !!localStorage.getItem('currentUser');
  }

  isTokenExpired() {
    const expiresIn = parseInt(localStorage.getItem('expiresIn'));
    if (expiresIn) {
      return Math.round((new Date()).getTime() / 1000) >= expiresIn;
    }
    return false;
  }

  createPaymentSession(data) {
    return this.transport.post('stripe/create', data);
  }

  fetchPaymentSession(data) {
    return this.transport.post('stripe/fetch', data);
  }

  getSubscriptionList() {
    return this.transport.get('subscription/plans');
  }

  validateCoupon(coupon) {
    return this.transport.get(`validate-coupon/${coupon}`);
  }

  checkEmail(email: string): Observable<any> {
    return this.transport.post(`auth/check-email`, {email});
  }

  requestPasswordReset(email: string): Observable<any> {
    return this.transport.post(`auth/request-password-reset`, {email});
  }

  validateResetPasswordToken(token: string): Observable<any> {
    return this.transport.post(`auth/validate-reset-password-token`, {token});
  }

  setupPassword(password: string, token: string): Observable<any> {
    return this.transport.post(`auth/setup-password`, {password, token});
  }
}
