import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ROUTE_PATHS } from '../../core/route-paths';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { OWNER_CPD, OWNER_NCI } from '../core/user';
import { PasswordValidators } from '../core/password-validators';
import { AuthenticationService } from '../../auth/services';

@Component({
  selector: 'app-setup-password',
  templateUrl: './setup-password.component.html',
  styleUrls: ['./setup-password.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SetupPasswordComponent implements OnInit {
  isPageLoading = false;
  isLoading = false;
  isSubmitted = false;
  error: string;
  email: string;
  owner = OWNER_CPD;
  showPassword = false;
  formGroup = new FormGroup({
    password: new FormControl(null, [
      Validators.required,
      Validators.minLength(8),
      PasswordValidators.atLeastOneNumber,
      PasswordValidators.atLeastOneCapitalLetter
    ])
  });

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthenticationService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.runTokenValidation();
  }

  get password() {
    return this.formGroup.get('password');
  }

  runTokenValidation() {
    this.isPageLoading = true;

    const {hash} = this.activatedRoute.snapshot.params;

    if (!hash) {
      this.router.navigate([ROUTE_PATHS.LOGIN]);
      return;
    }

    this.authService.validateResetPasswordToken(hash).subscribe(
      (response) => {
        const {result: {email, owner}} = response;

        this.email = email;
        this.owner = owner;

        this.isPageLoading = false;
      },
      ({result, error: {message}}) => {
        if (result) {
          this.email = result.email;
          this.owner = result.owner;
        }

        this.error = message;

        this.isPageLoading = false;
      }
    );
  }

  navigateLogin() {
    this.router.navigate([ROUTE_PATHS.LOGIN]);
  }

  handlePasswordSetup() {
    this.isSubmitted = true;

    if (!this.formGroup.valid) {
      return;
    }

    const {hash} = this.activatedRoute.snapshot.params;

    if (!hash) {
      this.router.navigate([ROUTE_PATHS.LOGIN]);
      return;
    }

    this.isLoading = true;

    this.authService.setupPassword(this.password.value, hash).subscribe(
      ({status, message, result}) => {
        if (status !== 0) {
          this.authService.saveUserData(result.userdata);
          this.router.navigate([ROUTE_PATHS.DASHBOARD]);
        } else {
          this.isLoading = false;
        }
      },
      ({error: {message}}) => {
        this.error = message?.token || message?.password;
        this.isLoading = false;
      }
    );
  }

  isNciUser() {
    return this.owner === OWNER_NCI;
  }
}
