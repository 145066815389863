<!--NCPD Logo-->
<div *ngIf="isNciUser()" class="p-4 bg-ncpd ncpd-login-logo animate__animated animate__zoomIn">
  <img src="/assets/app/media/img/logo-ncpd-square-1590px.png" class="max-w-115px"
       title="This account is provided to you by Nursing CPD"/>
</div>
<div class="card card-custom max-w-400px w-lg-400px min-w-300px">
  <!--STEP 1 SUBMIT EMAIL-->
  <ng-template [ngIf]="template === 'login'">
    <div id="access-step-1" class="card-body p-6 px-sm-15 width-100">
      <div class="my-7">
        <h2 class="font-weight-boldest">Login</h2>
        <p class="mt-4">Welcome to the CPD Portfolio portal.</p>
      </div>
      <!--Alert-->
      <div *ngIf="alert?.message" class="alert alert-custom text-left" role="alert"
           [ngClass]="'alert-light-' + alert?.status">
        <div class="alert-icon">
          <i class="fas font-size-h4"
             [ngClass]="{
								'fa-exclamation-circle': alert?.status === 'danger',
								'fa-exclamation-triangle': alert?.status === 'warning'
							 }"></i>
        </div>
        <div class="alert-text">{{ alert?.message }}</div>
      </div>
      <!--NCPD Expired User Notice-->
      <div *ngIf="isNciUser() && status === 'inactive'"
           class="my-5 p-5 bg-light-ncpd text-left animate__animated animate__zoomIn">
        Please contact <a href="https://www.nursingcpd.com.au/contact" target="_blank"
                          class="text-ncpd"><u>NursingCPD</u></a> regarding your subscription.
      </div>
      <!--Form-->
      <form [formGroup]="emailForm" (ngSubmit)="submitEmail()">
        <!--Email-->
        <div class="form-group text-left">
          <input
            [class.is-invalid]="email.invalid && isSubmitted"
            class="form-control py-4"
            formControlName="email"
            placeholder="Email address"
            type="text"
            name="email"
            autocomplete="on"
          />
          <div *ngIf="email.errors?.required && isSubmitted"
               class="invalid-feedback">
            Email is required.
          </div>
          <div *ngIf="email.errors?.email && isSubmitted"
               class="invalid-feedback">
            Enter valid Email.
          </div>
        </div>
        <div class="mt-7 mb-3">
          <button class="btn btn-primary min-w-115px py-3"
                  [ngClass]="{'spinner spinner-right spinner-light': isLoading}"
                  [disabled]="!isCaptchaSuccess || isLoading">
            Continue
          </button>
        </div>
        <p class="mt-lg-5">
          Don't have an account?
          <a class="" (click)="navigateToCreateAccount()" href="javascript:;">Sign up</a>
        </p>
      </form>
    </div>
  </ng-template>
  <!--STEP 2 LOGIN-->
  <ng-template [ngIf]="template === 'password'">
    <div id="access-step-2" class="card-body p-6 px-sm-15 width-100">
      <div class="my-7">
        <h2 class="font-weight-boldest">Login</h2>
        <p class="mt-4 mb-0">Welcome to the CPD Portfolio portal.</p>
        <p *ngIf="isNciUser()" class="text-ncpd animate__animated animate__zoomIn">Your account is provided by
          NursingCPD.</p>
      </div>
      <!--Form-->
      <form [formGroup]="passwordForm" (ngSubmit)="submitPassword()">
        <!--Alert-->
        <div *ngIf="alert?.message" class="alert alert-custom alert-light-danger text-left" role="alert">
          <div class="alert-icon">
            <i class="fas fa-exclamation-circle font-size-h4"></i>
          </div>
          <div class="alert-text">{{ alert?.message }}</div>
        </div>
        <!--Form Content-->
        <!--Email-->
        <div class="form-group mb-5">
          <div class="input-group">
            <input
              [class.is-invalid]="email.invalid && isSubmitted"
              class="form-control text-dark py-4"
              formControlName="email"
              placeholder="Email address"
              type="text"
              name="email"
              autocomplete="on"
            />
            <div class="input-group-append">
              <span class="input-group-text">
                <a class="" href="javascript:;" (click)="goBack()">Edit</a>
              </span>
            </div>
          </div>
        </div>
        <!--Password-->
        <div class="form-group mb-0 animate__animated animate__zoomIn">
          <div class="input-group">
            <input
              [class.is-invalid]="password.invalid && isSubmitted"
              class="form-control py-4"
              [type]="showPassword ? 'text' : 'password'"
              minlength="8"
              maxlength="20"
              placeholder="Password"
              formControlName="password"
              name="password"
              autocomplete="on"
            />
            <div class="input-group-append">
              <span class="input-group-text">
                <i class="font-size-md far" title="Show/hide password"
                   [ngClass]="showPassword ? 'fa-eye-slash' : 'fa-eye'"
                   (click)="showPassword=!showPassword"></i>
              </span>
            </div>
          </div>
          <div *ngIf="password.errors?.required && isSubmitted"
               class="text-left text-danger font-size-sm">
            Email required.
          </div>
          <div *ngIf="password.errors?.minlength && isSubmitted"
               class="text-left text-danger font-size-sm">
            Should be 8 characters or more.
          </div>
        </div>
        <!--Remember me / Forgot Password-->
        <div class="form-group d-flex flex-wrap justify-content-between align-items-center pl-2">
          <div class="checkbox-inline text-left mt-3" id="remember-me">
            <label class="checkbox checkbox-solid m-0 font-weight-normal text-muted">
              <input type="checkbox" name="remember" formControlName="remember"/>
              <span></span>Remember me</label>
          </div>
          <a class="mt-3 px-3" href="javascript:;" (click)="resetPassword()">Forgot password?</a>
        </div>
        <!--Buttons-->
        <div class="mt-7 mb-3">
          <button class="btn btn-primary min-w-115px py-3"
                  [ngClass]="{'spinner spinner-right spinner-light': isLoading}"
                  [disabled]="isLoading">login
          </button>
        </div>
        <p class="mt-lg-5">
          Not you?
          <a class="" href="javascript:;" (click)="goBack()">Go back</a>
        </p>
      </form>
    </div>
  </ng-template>
  <!--STEP 1.1A Send Link-->
  <ng-template [ngIf]="template === 'reset-password'">
    <div id="access-step-1.1" class="card-body p-6 px-sm-15 width-100 animate__animated animate__zoomIn">
      <div class="my-7">
        <h2 *ngIf="isCPDUser()" class="font-weight-boldest">Reset Password</h2>
        <h2 *ngIf="isNciUser()" class="font-weight-boldest">Set a Password</h2>
        <p *ngIf="isCPDUser()" class="mt-4">If you continue, we will email you a link to set a new password.</p>
        <div *ngIf="isNciUser()" class="mt-4 d-flex flex-column">
          <div
            class="mb-4 mx-5 text-ncpd py-2 bg-light-ncpd d-flex align-items-center flex-row justify-content-center text-left">
            <div class="mr-4">
              <i class="icon-xl flaticon-black text-ncpd"></i>
            </div>
            <div class="">
              Welcome Nursing CPD members.
            </div>
          </div>
          <p class="mb-0">To access your portfolio, you need to set a password. Click <span class="font-weight-bolder">continue</span>
            and we will email you the password link.</p>
        </div>
      </div>
      <div class="mb-3">
        <button class="btn btn-primary w-175px py-3"
                [ngClass]="{'spinner spinner-right spinner-light': isLoading}"
                [disabled]="isLoading"
                (click)="submitResetPasswordRequest()">
          Continue
        </button>
      </div>
      <div class="mb-7">
        <a class="text-muted" (click)="goBack()" href="javascript:;">Cancel</a>
      </div>
    </div>
  </ng-template>
  <!--STEP 1.1B Confirmation-->
  <ng-template [ngIf]="template === 'reset-password-success'">
    <div id="access-step-1.2" class="card-body width-100 py-lg-20">
      <div>
        <div class="d-flex flex-row justify-content-center font-size-h2 animate__animated animate__zoomIn">
          <div class="mr-4">
            <i class="icon-xxl fas fa-check-circle text-success"></i>
          </div>
          <div class="font-weight-boldest">
            Email Sent
          </div>
        </div>
        <p class="mt-5">
          Check your inbox for the email we just sent you.
        </p>
      </div>
      <div>
        <p class="mt-5 mb-2 text-muted">Having trouble? Contact us.<br> mail@cpdportfolio.com.au
        </p>
      </div>
    </div>
  </ng-template>
</div>
