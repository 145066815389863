import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthenticationService} from '../services';
import { ROUTE_PATHS } from '../../core/route-paths';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {

  role_id: any;

  constructor(private router: Router, private authService: AuthenticationService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    let routeLink: any = route;
    let valid = this.getCurrUser();
    if (valid) {
      if (this.role_id == 2) {
        return true;
      } else if (routeLink._routerState.url != '/user_management' && routeLink._routerState.url != '/style_guide') {
        return true;
      } else {
        this.router.navigate(['/not_found']);
        return false;
      }
    } else {
      localStorage.clear();
      this.router.navigate([ROUTE_PATHS.LOGIN], {queryParams: {returnUrl: state.url}});
      return false;
    }
  }

  getCurrUser() {
    if (this.authService.isTokenExpired()) {
      return false;
    }

    const curr: any = JSON.parse(localStorage.getItem('currentUser'));
    const token = JSON.parse(localStorage.getItem('token'));
    if (curr) {
      this.role_id = curr.role_id;
    }
    return curr != null && token != null;
  }
}
